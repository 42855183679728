/** @format */

import { NextPage } from 'next';
import Head from 'next/head';
import { InferGetServerSidePropsType } from 'next';
import { Product } from '../../types/product';
import { useEffect, useState } from 'react';
import { ReactElement } from 'react';
import $ from 'jquery';
import { shopApi } from '@edge/frontend.open-api';
import PlaceHolderLayout from 'components/PlaceHolderLayout';
import { event as gtag, pixel } from '@edge/frontend.component';
import { useRouter } from 'next/router';
import { orderApi } from '@edge/frontend.open-api';
import { getServerSidePropsWithClientRequestHeader } from 'utility';

const Index: NextPage & { getLayout: (page: ReactElement) => ReactElement } = ({ data: products }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const router = useRouter();
  const [scrollTop] = useState(0);

  const [getPromotion, { data: isPromotionOn }] = orderApi.edgeOrderApi.endpoints.getPromotion.useLazyQuery();

  useEffect(() => {
    getPromotion({ name: 'addedValuePromotion' });
  }, []);

  useEffect(() => {
    const onScroll = e => {
      if (e && e.target && e.target.documentElement) {
        const d = e.target.documentElement.scrollTop;
        if (d >= 1) $('header').addClass('sticky');
        else $('header').removeClass('sticky');
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  const customerGiftCardProductSKU = 'PD0UKZ182KYV9X';
  useEffect(() => {
    if (products) {
      gtag('view_item_list', {
        currency: 'USD',
        items: products?.map((product, index) => ({
          item_id: product.sku,
          item_name: product.name,
          item_brand: 'NavyZebra',
          item_category: 'Gift Card & Gift Certificate',
          item_list_id: 'Gift Card & Gift Certificate',
          item_list_name: 'Gift Card/Gift Certificate', //product.categories[0].name, ?
          index: index,
          price: product.sku === customerGiftCardProductSKU ? 0 : parseFloat(product.price),
        })),
      });
    }
  }, [products]);

  const selectItem = slug => {
    const item = products.filter(item => item.slug === slug)[0];
    gtag('select_item', {
      items: [
        {
          item_id: item.sku.toString(),
          item_name: item.name.toString(),
          item_brand: 'NavyZebra',
          item_category: 'Gift Card & Gift Certificate',
          price: item.sku === customerGiftCardProductSKU ? 0 : parseFloat(item.price),
        },
      ],
      item_list_id: 'Gift Card & Gift Certificate',
      item_list_name: 'Gift Card/Gift Certificate',
    });
  };
  const getOptionsFromProductAttributes = (product: Product, attributeName: string) => {
    return product.attributes?.find(attr => attr.name == attributeName)?.options;
  };

  return (
    <>
      <Head>
        <title>Shop - SoJo Spa Club</title>
      </Head>
      <div className="woocommerce">
        <ul className="products columns-3">
          {!!products &&
            [...products]
              .sort((p1, p2) => (p1.menu_order < p2.menu_order ? -1 : p1.menu_order > p2.menu_order ? 1 : 0))
              .filter(el => el.catalog_visibility == 'visible')
              .map(product => {
                return (
                  <li key={product.id} className="product type-product post-638 status-publish first instock product_cat-gift-cards has-post-thumbnail shipping-taxable purchasable product-type-simple">
                    <a
                      onClick={e => {
                        router.push(`/gift-certificate/${product.slug}/`);
                        selectItem(product.slug);
                      }}
                      className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                      style={{ cursor: 'pointer' }}
                    >
                      {product.images?.length > 0 && (
                        <img width="300" height="300" src={product.images[0].src} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt={product.name} decoding="async" loading="lazy" sizes="(max-width: 300px) 100vw, 300px" />
                      )}
                      <h2 className="woocommerce-loop-product__title">{product.name}</h2>
                      {getOptionsFromProductAttributes(product, 'alternativeName') && (
                        <span className="price">
                          {getOptionsFromProductAttributes(product, 'alternativeName').map(alternativeName => (
                            <bdi key={`${product.id}-${alternativeName}`}>{alternativeName}</bdi>
                          ))}
                        </span>
                      )}
                      {!product.has_options ? (
                        <span className="price">
                          <span className="woocommerce-Price-amount amount">
                            <bdi>
                              <span className="woocommerce-Price-currencySymbol">$</span>
                              {product.price}
                            </bdi>
                            {(product.id === 1228 || product.id === 1230) && <p style={{ color: 'red' }}>*Limited Time Offer</p>}
                          </span>
                        </span>
                      ) : (
                        <span className="price">
                          <span className="woocommerce-Price-amount amount">
                            <bdi>Choose your amount</bdi>

                            {isPromotionOn && <p style={{ color: 'red' }}>*Added Value Promotion</p>}
                          </span>
                        </span>
                      )}
                    </a>
                  </li>
                );
              })}
        </ul>
      </div>
    </>
  );
};

Index.getLayout = function getLayout(page: ReactElement) {
  return <PlaceHolderLayout pageName={'Shop'}>{page}</PlaceHolderLayout>;
};

export const getServerSideProps = getServerSidePropsWithClientRequestHeader(async (store, _context) => {
  const products = await store.dispatch(shopApi.edgeShopApi.endpoints.getProductsFromWp.initiate());
  return {
    props: {
      data: products.data as Product[],
    },
  };
});

export default Index;
